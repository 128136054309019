.service-page-div {
    text-align: center;
    height: auto;

    .service-page-text {
        margin: 3rem 0;

        .service-page-text-title {
            margin: 3rem 0;
            font-family: 'Mochiy Pop One';
            font-size: 40px;
            font-weight: 400;
            line-height: 58px;
            letter-spacing: 0em;
            text-align: center;
            color: #BA7373;
        }

        .service-page-text-context {
            margin: 2rem 0;
            font-family: 'Noto Sans TC', sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: #898989;
            white-space: pre-line;
        }
    }

    .service-page-service {
        background: #F4F1EF;
        width: 100%;
        .service-page-service-grid {
            margin: 2rem 0;
            justify-content: center;
            display: flex;

            .service-page-service-item {
                width: 300px;
                height: 280px;
                text-align: left;

                .service-page-service-title {
                    font-family: 'Mochiy Pop One';
                    font-size: 30px;
                    font-weight: 400;
                    line-height: 43px;
                    letter-spacing: 0em;
                    margin: 2rem 0 1rem 0;
                    color: #898989;
                }

                .service-page-service-text {
                    font-family: 'Noto Sans TC', sans-serif;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 29px;
                    letter-spacing: 0em;
                    white-space: pre-line;
                }

            }
        }
    }
}