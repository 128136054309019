.achievements-page-div {
    text-align: center;
    height: auto;

    .achievements-page-text {
        margin: 3rem 0;

        .achievements-page-text-title {
            margin: 3rem 0;
            font-family: 'Mochiy Pop One';
            font-size: 40px;
            font-weight: 400;
            line-height: 58px;
            letter-spacing: 0em;
            text-align: center;
            color: #BA7373;
        }

        .achievements-page-text-context {
            margin: 2rem 0;
            font-family: 'Noto Sans TC', sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: #898989;
            white-space: pre-line;
        }
    }
}