@media screen and (max-width:920px) {
    .toolbar {
        .toolbar-div-button {
            display: none;
        }
    }

    .home-page-div {
        .home-page-text {
            margin: 0;

            .home-page-text-title {
                margin: 1rem 0;
                font-family: Mochiy Pop P One;
                font-size: 30px;
                font-weight: 800;
                line-height: 58px;
                letter-spacing: 0em;
                text-align: center;
                color: #BA7373;
            }

            .home-page-text-context {
                margin: 2rem 0;
                font-family: Inter;
                font-size: 15px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                color: #898989;
            }
        }
    }

    .service-page-div {
        .service-page-text {
            margin: 0;

            .service-page-text-title {
                margin: 1rem 0;
                font-family: Mochiy Pop P One;
                font-size: 30px;
                font-weight: 800;
                line-height: 58px;
                letter-spacing: 0em;
                text-align: center;
                color: #BA7373;
            }

            .service-page-text-context {
                margin: 2rem 0;
                font-family: Inter;
                font-size: 15px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                color: #898989;
            }
        }
        .service-page-service {
            .service-page-service-grid {
                padding: 0 1rem;
            }
        }
    }

    .achievements-page-div {
        .achievements-page-text {
            margin: 0;

            .achievements-page-text-title {
                margin: 1rem 0;
                font-family: Mochiy Pop P One;
                font-size: 30px;
                font-weight: 800;
                line-height: 58px;
                letter-spacing: 0em;
                text-align: center;
                color: #BA7373;
            }

            .achievements-page-text-context {
                margin: 2rem 0;
                font-family: Inter;
                font-size: 15px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                color: #898989;
            }
        }
    }

    .footer-div{
        display: none;
    }
}