.home-page-div {
    text-align: center;
    padding: 1rem;
    width: 100vw;
    height: auto;

    .home-page-text {
        margin: 3rem 0;

        .home-page-text-title {
            margin: 3rem 0;
            font-family: 'Mochiy Pop One';
            font-size: 40px;
            font-weight: 400;
            line-height: 58px;
            letter-spacing: 0em;
            text-align: center;
            color: #BA7373;
        }

        .home-page-text-context {
            margin: 2rem 0 5rem 0;
            font-family: 'Noto Sans TC', sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            color: #898989;
            white-space: pre-line;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }
    .home-page-divider{
        background: #D9D9D9;
        width: 100%;
        height: 16px;
    }
}