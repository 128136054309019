.toolbar {
    justify-content: space-evenly;
    box-shadow: 0px 4px 4px 0px #00000040;
    background: #faf8f8;
    position: sticky;
    top: 0;
    z-index: 999;

    .toolbar-div-button {
        gap: 100px;
        display: flex;
    }
}