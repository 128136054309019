.service-pricing-details-content-div {
  position: relative;
  display: block;
  padding-bottom: 8%;

  .service-pricing-details-content-shadow {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 280px;
    background-color: #E8E8E8;
    z-index: -1 !important;
  }

  .pricing-details-div {
    display: flex;
    flex-direction: column;
    background-color: #F4F4F4;
    border-style: solid;
    border-color: #898989;
    border-radius: 1rem;
    border-width: 2px;
    width: 300px;
    height: 300px;
  }

  .pricing-details-head-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0rem 0.5rem 1rem;
    margin-right: 1rem;
    border-bottom: 0.2rem solid #BA7373;

    .pricing-details-head-text-title {
      margin-bottom: 1rem;
      color: #000;
      font-family: 'Inter';
      font-size: 24px;
      line-height: 29px;
      font-weight: 400;
    }

    .pricing-details-head-text-price {
      color: #BA7373;
      font-family: 'Inter';
      font-size: 30px;
      line-height: 36px;
      font-weight: 700;
    }
  }

  .pricing-details-content-ul {
    list-style: none;
    text-align: left;
    vertical-align: baseline;

    li {
      ::before {
        content: url(../Images/dot.png);
        color: #BA7373;
        display: inline-block;
        width: 2rem;
        margin-left: -1rem;
      }
    }
  }
}