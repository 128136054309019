@import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&family=Noto+Sans+TC&display=swap');
@import './_variables';
@import './_Toolbar';
@import './_HomePage';
@import './_ServicePage';
@import './_AchievementsPage';
@import './_PricingPage';
@import './_FooterPage';
@import './_responsive';

* {
    box-sizing: border-box;
}


body {
    font-family: var(--font-family);
    color: var(--text-color);
    margin: 0;
    padding: 0;
    min-height: 100%;
    max-width: 100vw;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}


.grid {
    display: flex;
    flex-wrap: wrap;
}

@for $var from 1 through $grid-sum {
    .col-#{$var} {
        position: relative;
        max-width: 100% * ($var / $grid-sum);
        flex: 0 0 (100% * ($var / $grid-sum));
        padding-left: $gutter-width / 2;
        padding-right: $gutter-width / 2;
        margin-bottom: 5px;
    }
}

.flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}