.footer-div {
  padding: 5rem 5% 10rem 5%;
  background-color: #F4F1EF;
  display: flex;
  flex-direction: row;

  .footer-content-div {
    margin-left: 5%;

    .footer-content-text-title {
      font-family: 'Mochiy Pop P One';
      font-size: 30px;
      font-weight: 400;
      line-height: 36px;
      color: #898989;
    }

    .footer-content-text-content {
      font-family: 'Mochiy Pop P One';
      font-size: 24px;
      font-weight: 400;
      line-height: 29px;
      margin-top: 1rem;
      color: #898989;
    }

    .contact-content {
      display: flex;
      gap: 0.5rem;
    }

    .service-content {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem 4.5rem;
    }

    img {
      height: 7rem;
    }
  }
}